/* eslint-disable react-hooks/rules-of-hooks */
import jwtDecode from 'jwt-decode';


/* const setAuthToken = (token) => {
  localStorage.setItem('token', token);
}; */

/* const getAuthToken = () => 
   localStorage.getItem('token') ;
 */
const getUser = (token) => {
  return jwtDecode(token);
  /* return jwtDecode(localStorage.getItem('token')); */
};

export {/* setAuthToken , getAuthToken ,*/ getUser };
