import axios from 'axios';
import { getEntity } from 'utils/appFunctions';

import { getUser/* , getAuthToken, setAuthToken */ } from 'utils/auth';

const API_URL = process.env.REACT_APP_API_URL;

const requestAuthToken = async (token) => {
  try {
    const response = await axios.post(`${API_URL}/auth/token`, {
      token,
    });

    const jwtToken = response.data.token;
    return jwtToken;
  } catch (error) {
    window.parent.postMessage('error', '*');
    throw error;
  }
};

const requestChart = async (type, token) => {
  const user = getUser(token);

  const fundhouses = user.allow.length !== 0;
  const entity = getEntity(user, fundhouses);
  let entityFilters;
  let url = API_URL;
  if (!fundhouses) url = `${url}/dist`;

  if (entity) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    entityFilters = /* localStorage && JSON.parse(localStorage.getItem(`managers_filters_${entity}`)) */ undefined;
    try {
      const response = await axios.get(
        `${url}/${type}/${entity}?currency=${entityFilters && entityFilters.currency? entityFilters.currency : 'EUR'}&period=12LM`,
        config
      );
      return response.data.data;
    } catch (error) {
      window.parent.postMessage('error', '*');
      throw error;
    }
  }
};

export { requestAuthToken, requestChart };
