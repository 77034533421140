import { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import jwtDecode from 'jwt-decode';

import Aum from 'widgets/Aum';
import FlowsME from 'widgets/FlowsME';
import Predictive from 'widgets/Predictive';
import useStore from 'store';
import Loader from 'components/base/Loader';

import { getEntity } from 'utils/appFunctions';
/* import { getUser, } from 'utils/auth'; */
import { requestAuthToken } from 'services/api';

const App = () => {
  const [loading, setLoading] = useState(true);
  const userState = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const setUserToken = useStore((state) => state.setUserToken);

  let entityFilters;
  const user =/*  getUser() || */ userState;
  const fundhouses = user?.allow.length !== 0;
  const entity = user && getEntity(user, fundhouses);
  entityFilters =  /* localStorage && JSON.parse(localStorage.getItem(`managers_filters_${entity}`)) */ undefined;
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    requestAuthToken(token).then((res) => {
      setUser(jwtDecode(res));
      setUserToken(res);
      setLoading(false)});
  }, [setUser, setUserToken]);

  if (loading) return <Loader />;
  
  return (
    <Router basename={'/widgets'}>
      <Switch>
        <Route path="/aum">
          <Aum entity={entity} entityFilters={entityFilters && entityFilters.currency ? entityFilters : {currency: 'EUR'}}/>
        </Route>
        <Route path="/flows">
          <FlowsME entity={entity} entityFilters={entityFilters && entityFilters.currency ? entityFilters : {currency: 'EUR'}}/>
        </Route>
        <Route path="/predictive">
          <Predictive />
        </Route>
        <Redirect from="/" to="/aum" />
      </Switch>
    </Router>
  );
};

export default App;
