import { useEffect, useState } from 'react';

import BoxWrapper from 'components/scaffolding/BoxWrapper';
import Loader from 'components/base/Loader';

import styles from './index.module.css';
import Button from 'components/base/Button';

const Predictive = () => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(5);
    window.parent.postMessage('loaded', '*');
  }, []);

  if (!data) return <Loader />;

  return (
    <BoxWrapper>
      <div className={styles.container}>
        <div className={styles.interested}>
          <h2>{data}</h2>
          <p>distributors are interested in buying your fund</p>
        </div>
        <Button onClick={() => console.log('aaa')}>Discover who</Button>
      </div>
    </BoxWrapper>
  );
};

export default Predictive;
